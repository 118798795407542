* {
  padding: 0px;
  border: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Maitree', serif;
}

/*///////////////////////////////

////////// Text Styles //////////

///////////////////////////////*/

h1 {
  font-family: 'Libre Baskerville', serif;
  font-style: normal;
  font-size: 52px;
  font-weight: 400;
}

h2 {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 42px;
  font-weight: 400;
}

h3 {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
}

h4 {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
}

h5 {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

p {
  font-family: 'Maitree', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.small {
  font-family: 'Maitree', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

/*///////////////////////////////

////////// Colour styles //////////

///////////////////////////////*/

/* Pink */

.pink {
  color: #ECB3B2;
}

.pink-background {
  background: #ECB3B2;
}

.dark-pink-1 {
  color: #BD8F8E;
}

.dark-pink-1-background {
  background-color: #BD8F8E;
}

.dark-pink-2 {
  color: #8E6B6B;
}

.dark-pink-2-background {
  background-color: #8E6B6B;
}

.light-pink-1 {
  color: #F4D1D1;
}

.light-pink-1-background {
background-color: #F4D1D1;
}

.light-pink-2 {
  color: #F0C2C1;
}

.light-pink-2-background {
  background-color: #F0C2C1;
}

/* Blue */

.blue {
  color: #0072FA;
}

.blue-background {
  background-color: #0072FA;
}

.blue-dark-1 {
  color: #005BC8;
}

.blue-dark-1-background {
  background-color: #005BC8;
}

.blue-dark-2 {
  color: #004496;
}

.blue-dark-2-background {
  background-color: #004496;
}

.blue-light-1 {
  color: #66AAFC;
}

.blue-light-1-background {
  background-color: #66AAFC;
}

.blue-light-2 {
  color: #338EFB;
}

.blue-light-2-background {
  background-color: #338EFB;
}

/* Black */

.black {
  color: #293541;
}

.black-background {
  background-color: #293541;
}

/* White */

.white {
  color: #FCFFFC;
}

.white-background {
  background-color: #FCFFFC;
}

/* Layout styles */

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}