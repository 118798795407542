/* Reccuring CTA form */
.cta-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
}

.cta-form > input {
    width: 60%;
    height: 45px;
    border: 1px solid #0072FA;
    border-radius: 5px;
    padding-left: 20px;
}

.cta-form > input::placeholder {
    color: #0072FA;
}

.cta-form > input:focus {
    border: 2px solid #0072FA;
    outline: none;
}


.cta-form > button {
    width: 40%;
    height: 45px;
    border-radius: 5px;
}

/* Hero section */
#hero {
    width: 100%;
    min-height: 820px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-image: url("../../images/temple.jpeg");
    background-size: cover;
    background-position: bottom;
}

#hero > nav {
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
align-items: center;
padding: 20px 90px;
}

#hero > nav > img {
    width: 75px;
    height: 75px;
}

#hero > nav > button {
    padding: 10px 50px;
    border-radius: 4px;
    height: 40px;
}

#hero > article {
    display: flex;
    max-width: 650px;
    flex-direction: column;
    align-items: flex-start;
    padding: 240px 40px 0px;
    gap: 20px;
}

#hero > article * {
    max-width: 600px;
}

#hero > article > button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
}

/* Unique experiences section */
#unique-experiences {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 120px;
    padding: 100px 90px;
    gap: 40px;
}

#unique-experiences > .text-content {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
}

#image-collage {
    width: 600px;
    height: 750px;
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}

.collage-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 10px;
    gap: 10px;
}

#collage-castles {
    grid-column: 1 / span 1;
    grid-row: 1 / span 4;


    background: 
        linear-gradient(0deg, rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)),
        url("../../images/castle.jpeg");
    background-size: cover;
    background-position: center;
    border: 5px solid #F4D1D1;
}

#collage-speakeasy {
    grid-column: 2 / span 2;
    grid-row: 1 / span 2;

    background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)),
        url("../../images/speakeasy.jpeg");
    background-size: cover;
    background-position: center;
    border: 5px solid #66AAFC;
}

#collage-kolkata {
    grid-column: 2 / span 1;
    grid-row: 3 / span 2;

    background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)),
        url("../../images/chai.jpeg");
    background-size: cover;
    background-position: center;
    border: 5px solid #66AAFC;
}

#collage-haunted-tours {
    grid-column: 3 / span 1;
    grid-row: 3 / span 3;

    background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)),
        url("../../images/hike.jpeg");
    background-size: cover;
    background-position: center;
    border: 5px solid #F4D1D1;
}

#collage-hieroglyphics {
    grid-column: 1 / span 2;
    grid-row: 5 / span 3;

    background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)),
        url("../../images/hieroglyphics.jpeg");
    background-size: cover;
    background-position: center;
    border: 5px solid #F4D1D1;
}

#collage-victoria-falls {
    grid-column: 3 / span 1;
    grid-row: 6 / span 2;

    background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)),
        url("../../images/victoria-falls.jpeg");
    background-size: cover;
    background-position: center;
    border: 5px solid #66AAFC;
}

.collage-location {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 6px;
}

.collage-location > img {
    width: 20px;
}





/* Lifestyle holidays section */
#lifestyle-holidays {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 120px;
    padding: 100px 90px;
    gap: 40px;
}

#cards {
    width: 600px;
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;

}

#fire-bending-card {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
}

#waterfalls-card {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
}

#food-card {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
}

#dance-card {
    grid-column: 2 / span 1;
    grid-row: 2 / span 2;
}

.card {
    width: 260px;
    height: 340px;
    justify-self: center;
    align-self: center;
    border-radius: 10px;
}

.card-image {
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
}

#fire-image {
    background-image: url("../../images/fire-bending.jpeg");
    background-size: cover;
    background-position: center;
}

#waterfalls-image {
    background-image: url("../../images/waterfalls.jpeg");
    background-size: cover;
    background-position: center;
}

#food-image {
    background-image: url("../../images/food.jpeg");
    background-size: cover;
    background-position: center;
}

#dance-image {
    background-image: url("../../images/dance.jpeg");
    background-size: cover;
    background-position: center;
}

.card-location {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 6px;
}

.card-location > img {
    width: 15px;
}

.card-text {
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border-radius: 0px 0px 10px 10px;
}

.card-text > * {
    width: 100%;
    text-align: center;
}

#lifestyle-holidays > .text-content {
    width: 600px;
}

/* Quality section */
#quality {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 200px 10px;
    gap: 40px;
}

/* Get early access section */
#get-early-access {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 200px 10px;
    gap: 40px;
}

#get-early-access > .cta-form {
    max-width: 600px;
}

footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

}